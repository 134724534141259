<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <!-- Botão para abrir a câmara -->
        <v-btn @click="startScanner" v-if="!isCameraActive" class="camera-button mt-8" icon>
          <v-img class="camera-icon" src="@/assets/photo-camera.svg" contain />
        </v-btn>

        <!-- Botão para parar a câmara -->
        <v-btn icon @click="stopScanner" v-if="isCameraActive">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="12" class="camera-wrapper">
        <div id="reader" class="qr-scanner"></div>
      </v-col>

      <v-col cols="12">
        <p v-if="qrCodeData">QR Code: {{ qrCodeData }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {  Html5Qrcode } from "html5-qrcode";

export default {
  name: "ReadQRCode",
  data() {
    return {
      qrCodeData: null,
      isCameraActive: false,
      scanner: null, // Instância do scanner
      selectedCamera: "environment", // Começa sempre com a traseira
    };
  },
  methods: {
    async startScanner() {
      if (this.isCameraActive) return;

      try {
        // Verifica se a câmara traseira está disponível
        const devices = await Html5Qrcode.getCameras();
        if (devices.length === 0) throw new Error("Nenhuma câmara disponível");

        // Seleciona a câmara traseira, se existir
        const backCamera = devices.find((device) => device.label.toLowerCase().includes("back"));
        const selectedCameraId = backCamera ? backCamera.id : devices[0].id; // Se não houver traseira, pega a primeira disponível

        this.scanner = new Html5Qrcode("reader");

        this.scanner.start(
          selectedCameraId,
          {
            fps: 10, // Frames por segundo
            qrbox: { width: 250, height: 250 }, // Caixa de leitura do QR
          },
          (decodedText) => {
            this.qrCodeData = decodedText;
            window.location.href = decodedText; // Redireciona automaticamente
          },
          (errorMessage) => {
            console.warn("Falha na leitura do QR Code:", errorMessage);
          }
        );

        this.isCameraActive = true;
      } catch (error) {
        console.error("Erro ao abrir a câmara:", error.message);
      }
    },

    stopScanner() {
      if (this.scanner) {
        this.scanner.stop().then(() => {
          this.scanner.clear();
          this.scanner = null;
          this.isCameraActive = false;
        });
      }
    },
  },
};
</script>

<style>
.qr-scanner {
  width: 100%;
  max-width: 400px;
  margin: auto;
  border: 3px solid #ff0000; /* Borda vermelha para ajudar no alinhamento */
  border-radius: 8px;
}

.camera-icon {
  width: 60px;
  height: 60px;
}
</style>
