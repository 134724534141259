<template>
    <v-card
      class="mt-4 mb-4 pa-4 hover-card"
      elevation="3"
      style="height: 150px; border-radius: 16px; background: linear-gradient(145deg, #ffffff, #f0f0f0); box-shadow: 4px 4px 8px #d1d1d1, -4px -4px 8px #ffffff; transition: all 0.3s ease;"
    >
      <v-row align="center" no-gutters>
        <!-- Ícone -->
        <v-col
          cols="auto"
          class="d-flex align-center justify-center"
          style="background: linear-gradient(145deg, #2d3436, #434b4f); width: 60px; height: 60px; border-radius: 14px; box-shadow: inset 3px 3px 5px #202225, inset -3px -3px 5px #3e4448; transition: all 0.3s ease;"
        >
          <v-icon color="white" large>{{ icon }}</v-icon>
        </v-col>
        <!-- Texto -->
        <v-col>
          <v-card-title
            class="text-subtitle-2 font-weight-medium mt-0 mb-2"
            style="color: #7a7f85; font-size: 14px; text-transform: uppercase; letter-spacing: 1px;"
          >
            {{ title }}
            <label class="ml-2" style="color: #7a7f85; font-size: 10px; text-transform: uppercase; letter-spacing: 1px;" v-if="subtitle != null">
              - {{ subtitle }}
            </label>
          </v-card-title>
          <v-card-subtitle style="color: #7a7f85; font-size: 10px; text-transform: uppercase; letter-spacing: 1px;">
            {{subtitle2 == null ? '&nbsp;' : subtitle2}}
          </v-card-subtitle>
          <v-card-text>
            <h3 class="mb-0 font-weight-bold" :style="bulleted ? 'color: #2c3e50; font-size: 12px;' : 'color: #2c3e50; font-size: 24px;'" v-html="animatedText">
            
            </h3>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </template>
  
  <script>
  export default {
    name: "StyledCard",
    props: {
      title: String,
      subtitle: {
        typeof: String,
        default: null
      },
      text: {
        type: [String, Number], // Aceita string ou número
        required: true,
      },
      icon: String,
      bulleted:{
        typeof: Boolean,
        default: false
      },
      subtitle2:{
        typeof: String,
        default: null
      }
    },
    data() {
      return {
        animatedText: this.text, // Inicializa o texto animado
      };
    },
    watch: {
      text: {
        immediate: true,
        handler(newValue) {
          if (typeof newValue === "number") {
            this.startCounter(newValue);
          } else {
            this.animatedText = newValue; // Atualiza diretamente se for string
          }
        },
      },
    },
    methods: {
      startCounter(target) {
        const duration = 1000; // Duração da animação em ms
        const startTime = performance.now();
  
        const animate = (currentTime) => {
          const elapsedTime = currentTime - startTime;
          const progress = Math.min(elapsedTime / duration, 1); // Garante que não ultrapasse 100%
          this.animatedText = Math.floor(progress * target); // Atualiza o valor animado
  
          if (progress < 1) {
            requestAnimationFrame(animate); // Continua a animação
          }
        };
  
        requestAnimationFrame(animate);
      },
    },
  };
  </script>
  
  <style scoped>
  .hover-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .hover-card:hover {
    transform: translateY(-5px) scale(1.03); /* Eleva e aumenta ligeiramente */
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1), -6px -6px 12px rgba(255, 255, 255, 0.9); /* Sombra mais pronunciada */
  }
  </style>
  