<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-domain</v-icon> <span>Gestão de Departamentos</span>
        </h1>
  
        <div style="position:relative">  
          <v-container>
            <v-speed-dial
              absolute
              v-model="speedDial"
              top
              right
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      color="primary"
                      dark
                      x-large
                      fab
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Opções</span>
                </v-tooltip>
              </template>
              <v-tooltip right color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="secondary"
                    large
                    to="/admin/company-space/departments/create"
                    v-if="$root.session.hasPermission(['super', 'departments.create'])"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Registar</span>
              </v-tooltip>
            </v-speed-dial>
          </v-container>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            class="mt-16"
            locale="pt-pt"
          >
          
          <template v-slot:item.responsible="{item}">
              {{item.responsible == null ? '' : item.responsible.name}}
            </template>

            <template v-slot:item.actions="{item}">
              <IconView
                class="mr-2"
                @click="viewDepartment(item)"
              />
              <IconRemove
                class="mr-2"
                @on-submit="deleteDepartment(item)"
              />
            </template>
          </v-data-table>
        </div>
        
    </section>
  </template>
  <script>
  import IconRemove from '@/components/ui/IconRemove.vue';
  import IconView from '@/components/ui/IconView.vue';
  import Departments from "@/api/Departments.js";
  
  export default {
    components: {
      IconRemove,
      IconView
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchDepartments()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "GPD - Gestão de Departamentos";
  
   
    },
    data: () => ({
      headers: [
        {
          text: 'Referência',
          sortable: true,
          value: 'reference_ext',
        },
        {
          text: 'Nome',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Responsável',
          sortable: true,
          value: 'responsible',
        },
        { 
          text: 'Opções', 
          value: 'actions', 
          sortable: false 
        },
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      filter: {
        
      },
      users:[]
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "departments.read"])) {
        this.$router.push('/no-permission');
      }
  
      this.fillBaseData();
    },
    methods:{
      fillBaseData() {
  
        if(localStorage["departments-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["departments-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchDepartments(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["departments-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Departments.search(request).then(response => {
          
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetDepartment()
      {
        this.filter = {};
  
        this.searchDepartments();
      },
      viewDepartment(item){
        this.$router.push('/admin/company-space/departments/' + item.id);
      },
      deleteDepartment(item){
        Departments.delete(item.id)
          .then(() => {
            this.searchDepartments();
          });
      },
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Gestão de Departamentos',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  