<template>
    <v-container fluid>
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <h3>Gantt de Férias</h3>
                    </v-card-title>
                    <v-card-subtitle>
                        <v-row>
                            <v-col cols="12" md="2">
                                <v-autocomplete
                                    :items="getYears"
                                    v-model="selectedYear"
                                    dense outlined
                                    label="Ano *"
                                />
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-autocomplete
                                    :items="getMonths"
                                    v-model="selectedMonths"
                                    dense outlined
                                    label="Mês(es) *"
                                    item-text="text"
                                    item-value="value"
                                    multiple
                                />
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-autocomplete
                                    v-model="selectedName"
                                    :items="workers"
                                    dense outlined
                                    label="Colaborador"
                                />
                            </v-col>
                            <v-col cols="12" md="auto">
                                <v-btn icon @click="increaseZoom" :disabled="zoom >= 100">
                                    <v-icon>mdi-magnify-plus</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <v-btn icon @click="decreaseZoom" :disabled="zoom <= 20">
                                    <v-icon>mdi-magnify-minus</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="12" md="auto" class="font-weight-bold mr-2">
                                Legenda:
                            </v-col>
                            <v-col cols="12" md="auto" class="weekend mr-2">
                                Fim de Semana
                            </v-col>
                            <v-col cols="12" md="auto" class="public-holiday white--text mr-2">
                                Feriado
                            </v-col>
                            <v-col cols="12" md="auto" class="holiday white--text mr-2">
                                Férias
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                        <v-simple-table dense class="full-width" :style="{ zoom: zoom + '%' }">
                            <thead>
                                <tr>
                                    <th :colspan="totalDaysInMonths + 1" class="text-center">{{ selectedYear }}</th>
                                </tr>
                                <tr >
                                    <th class="fixed-column"></th>
                                    <th v-for="month in selectedMonths" :key="month" class="text-center" :colspan="daysInMonth(month)">{{ getMonthName(month) }}</th>
                                </tr>
                                <tr>
                                    <th class="nowrap fixed-column"></th>
                                    <th v-for="(day, index) in daysArray" :key="index">{{ day }}</th>
                                </tr>
                                <tr>
                                    <th class="nowrap text-center fixed-column">Nome</th>
                                    <th v-for="(day, index) in daysArray" :key="index">
                                        {{ getDayOfWeek(day) }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(colaborador, key) in filteredHolidays" :key="key">
                                    <td class="nowrap fixed-column">{{ key }}</td>
                                    <td v-for="(day, index) in daysArray" :key="index"
                                        :class="isWeekend(day) ? 'weekend' : isHoliday(colaborador, day)">
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-text class="my-7 px-12">
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="notes"
                                    label="Notas"
                                    outlined
                                    dense
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" class="text-center">
                                <v-btn color="primary" @click="saveNotes">Gravar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Holidays from "@/api/Holidays.js";
import CalendarEvents from '@/api/CalendarEvents.js'

export default {
    name: "HolidaysGantt",
    data() {
        const today = new Date();
        return {
            calendar_events: [],
            loading: true,
            holidays: null,
            workers: [],
            selectedMonths: [today.getMonth() + 1],
            selectedYear: today.getFullYear(),
            selectedName: null,
            notes: '',
            zoom: 100
        };
    },
    watch: {
        selectedMonths(newValue) {
            const sortedMonths = [...new Set(newValue)].sort((a, b) => a - b);
            if (JSON.stringify(sortedMonths) !== JSON.stringify(this.selectedMonths)) {
                this.selectedMonths = sortedMonths;
            }
        }
    },
    computed: {
        getYears() {
            const currentYear = new Date().getFullYear();
            const startYear = 2025;
            return Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);
        },
        getMonths() {
            const months = [
                "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", 
                "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
            ];
            return months.map((month, index) => ({ text: month, value: index + 1 }));
        },
        totalDaysInMonths() {
            return this.selectedMonths.reduce((sum, month) => sum + new Date(this.selectedYear, month, 0).getDate(), 0);
        },
        daysArray() {
            let days = [];
            this.selectedMonths.forEach(month => {
                let daysInMonth = new Date(this.selectedYear, month, 0).getDate();
                for (let i = 1; i <= daysInMonth; i++) {
                    days.push(`${i}/${month}`);
                }
            });
            return days;
        },
        filteredHolidays() {
            if (this.selectedName) {
                return { [this.selectedName]: this.holidays[this.selectedName] };
            }
            return this.holidays;
        }
    },
    mounted() {
        this.fillBaseData();
    },
    methods: {
        isWeekend(day) {
            const [dayNum, month] = day.split('/').map(Number);
            const date = new Date(this.selectedYear, month - 1, dayNum);
            return date.getDay() === 0 || date.getDay() === 6;
        },
        fillBaseData() {
            let data = { year: this.selectedYear };
            Holidays.gantt(data).then(resp => {
                
                this.holidays = resp.data.holidays;
                this.notes = resp.data.notes;
                this.workers = Object.keys(this.holidays);
                this.loading = false;
            });

            data = {
                year: this.selectedYear
            }
            CalendarEvents.list(data).then((resp)   =>  {
                this.calendar_events = resp.data
            })
        },
        getDayOfWeek(day) {
            const [dayNum, month] = day.split('/').map(Number);
            const date = new Date(this.selectedYear, month - 1, dayNum);
            const weekdays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
            return weekdays[date.getDay()];
        },
        isHoliday(colaborador, day) {
            const [dayNum, month] = day.split('/').map(Number);
            const checkDate = new Date(this.selectedYear, month - 1, dayNum);

            // Verifica se é feriado
            const isPublicHoliday = this.calendar_events.some(event => {
                const eventDate = new Date(event.day_event);
                return (
                eventDate.getFullYear() === this.selectedYear &&
                eventDate.getMonth() === checkDate.getMonth() &&
                eventDate.getDate() === checkDate.getDate()
                );
            });

            if (isPublicHoliday) {
                return 'public-holiday'; // 🟡 nova classe
            }

            // Verifica férias do colaborador
            for (const [start, end, status] of colaborador) {
                const startDate = new Date(start);
                const endDate = new Date(end);

                if (checkDate >= startDate && checkDate <= endDate) {
                return status === 'approved' ? 'approved-holiday' : 'holiday';
                }
            }

            return '';
        },
        increaseZoom() {
            if (this.zoom < 100) this.zoom += 10;
        },
        decreaseZoom() {
            if (this.zoom > 20) this.zoom -= 10;
        },
        daysInMonth(month) {
            return new Date(this.selectedYear, month, 0).getDate();
        },
        getMonthName(month) {
            return this.getMonths.find(m => m.value === month).text;
        },
        saveNotes() {
            console.log("dados para as notas: ")
            console.log("notas: ", this.notes);
            console.log("selectedYear: ", this.selectedYear);

            const data = {
                interval: [this.selectedYear],
                notes: this.notes
            };

            Holidays.saveNotes(data).then(resp => {
                console.log(resp);
            });
        }
    }
};
</script>

<style scoped>
th, td {
    text-align: center;
    padding: 8px;
    border: 1px solid #ccc;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.public-holiday {
  background-color: #adacac; /* amarelo dourado */
  color: black;
}
.full-width {
    width: 100%;
}

.holiday {
    background-color: #2196F3;
    color: white;
}

.approved-holiday {
    background-color: #4CAF50; 
    color: white;
}

.weekend{
    background-color: gray;
    color: white;    
}

.fixed-column {
    position: sticky;
    left: 0;
    background: white;
    z-index: 2;
    font-weight: bold;
}

</style>
