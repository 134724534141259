import BaseApi from "./BaseApi";

export default class Diary extends BaseApi{
    construct() {}
    //admin.diaries.read.update

    static async markRead(diary_id, user_id){
        return await Diary.getAxiosInstance().post(Diary.getApiUrl() + 'read/' + diary_id + '/' + user_id);
    }

    static async list(params) {
        let url = Diary.getApiUrl() + "list";

        return Diary.getAxiosInstance().get(url,{params: params});
    }

    static async reject(id, params){
        let url = Diary.getApiUrl() + "reject/" + id;

        return Diary.getAxiosInstance().post(url,params);
    }

    static async search(params) {
        return await Diary.getAxiosInstance().get(Diary.getApiUrl(),{params: params});
    }

    static async searchStorybook(params) {
        return await Diary.getAxiosInstance().get(Diary.getApiUrl() + 'story-book',{params: params});
    }    

    static async create(params) {
        return await Diary.getAxiosInstance().post(Diary.getApiUrl(),params);
    }

    static async createImage(params, id) {
        return await Diary.getAxiosInstance().post(Diary.getApiUrl()+ id + "/files",params);
    }

    static async deleteImage(fileId, id) {
        return await Diary.getAxiosInstance().delete(Diary.getApiUrl()+ id + "/files/" + fileId);
    }

    static async update(params, id) {
        return await Diary.getAxiosInstance().post(Diary.getApiUrl() + id,params);
    }

    static async delete(id) {
        return await Diary.getAxiosInstance().delete(Diary.getApiUrl() + id);
    }

    static async find(id) {
        return await Diary.getAxiosInstance().get(Diary.getApiUrl() + id);
    }

    static async export(params) {
        return await Diary.getAxiosInstance().get(Diary.getApiUrl() + 'export', {params: params});
    }

    static async listStatus() {
        let url = Diary.getApiUrl() + "status";

        return Diary.getAxiosInstance().get(url);
    }

    static async getDiaryArticle(id){
        let url = Diary.getApiUrl() + 'get-diary-articles/' + id

        return Diary.getAxiosInstance().get(url);
    }

    static async setDiaryArticle(id, params){
        let url = Diary.getApiUrl() + 'set-diary-articles/' + id

        return Diary.getAxiosInstance().post(url, params);
    }

    static async saveAsDraft(params){
        let url = Diary.getApiUrl() + 'drafts'

        return Diary.getAxiosInstance().post(url, params);
    }

    static async getDraft(params){
        let url = Diary.getApiUrl() + 'drafts'

        return Diary.getAxiosInstance().get(url,{params:params});
    }

    static async getDraftById(id){
        let url = Diary.getApiUrl() + 'drafts/' + id

        return Diary.getAxiosInstance().get(url);
    }

    static async checkUsersInDay(params){
        let url = Diary.getApiUrl() + 'check-users-in-day'
        return await Diary.getAxiosInstance().get(url,{params: params});
    }

    static async checkUsersInDayFree(params){
        let url = Diary.getApiUrl() + 'check-users-in-day/free'
        return await Diary.getAxiosInstance().get(url,{params: params});
    }

    static async checkMonth(params){
        let url = Diary.getApiUrl() + 'month-guide'
        return await Diary.getAxiosInstance().get(url,{params: params});
    }

    static async getLast(data) {
        return await Diary.getAxiosInstance().get(Diary.getApiUrl() + 'get-last', {params: data});
    }

    static async checkWorkCreationValidation(params){
        return await Diary.getAxiosInstance().get(Diary.getApiUrl() + 'validation/create', {params: params})
    }

    static async checkValidCode(work_id, code){
        let data = {
            work_id: work_id,
            code: code
        }
        return await Diary.getAxiosInstance().get(Diary.getApiUrl() + 'valid-code', {params: data})
    }

    static async approve(id, approval) {
        let url = Diary.getApiUrl() + id + "/approve/";

        let params = { approval: approval };

        return Diary.getAxiosInstance().patch(url, params);
    }

    static async getPrevious(params){
        let url = Diary.getApiUrl() + "get-previous";

        return Diary.getAxiosInstance().get(url, {params: params});
    }

    static async getNext(params){
        let url = Diary.getApiUrl() + "get-next";

        return Diary.getAxiosInstance().get(url, {params: params});
    }

    static async approveTopic(params){
        return await Diary.getAxiosInstance().post(Diary.getApiUrl() + 'approve-topic/' + params.id,params);
    }

    static async disapproveTopic(params){
        return await Diary.getAxiosInstance().post(Diary.getApiUrl() + 'disapprove-topic/' + params.id,params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/diaries/";
    }
}