<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h2>Calendário de {{ getMonthName(month) }} {{ year }}</h2>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <div class="calendar" :class="{ 'no-weekends': !weekends }">                  

                    <div class="day-header" v-for="day in filteredWeekdays" :key="day">
                        {{ day }}
                    </div>

                    <div v-for="n in emptyCells" :key="'empty-' + n" class="day-cell empty"></div>

                    <div class="day-cell" v-for="day in filteredDays" :key="day.date.getTime()" :class="{ 'weekend': isWeekend(day.date) }">
                    <div class="day-header">
                  
                        <span class="day-number">
                            <label class="month-label" v-if="day.day === 21 || day.day === 1">
                                {{ getMonthName(day.date.getMonth() + 1, false) }}
                            </label>
                            {{ day.day }}
                        </span>
                    </div>

                    <div v-for="event in day.events" :key="event.name" class="event">
                        <!--DESKTOP-->         
                        <v-btn :style="{ backgroundColor: event.color == 'blue' ? '#d6eaf8' : '' }" elevation="4" text block rounded 
                            @click="openEventModal(event)" class="d-none d-lg-block font-weight-thin" outlined>
                            {{ event.name }}<v-icon class="ml-2" small color="white">mdi-eye</v-icon>
                        </v-btn>
                        <!--DESKTOP-->              
                        <!--MOBILE -->
                        <v-btn icon fab x-small @click="openEventModal(event)" class="d-lg-none primary">
                            <v-icon color="white">mdi-eye</v-icon>
                        </v-btn>         
                        <!--MOBILE -->
                    </div>
                </div>

                </div>
            </v-col>
        </v-row>

        <!-- Modal para exibir detalhes do evento -->
        <v-dialog v-model="showModal" max-width="500px">
            <v-card v-if="selectedEvent">
                <v-card-title class="primary white--text">
                    Dia {{ getFormattedDate(selectedEvent.start) }}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-row>
                        <v-col cols="12"><strong>Código:</strong> {{ selectedEvent.object.code }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12"><strong>Obra:</strong> {{ selectedEvent.object.name }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <strong>Entrada:</strong> 
                            {{ selectedEvent.object.started_hour }}:{{ formatMinute(selectedEvent.object.started_minute) }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <strong>Saída:</strong> 
                            {{ selectedEvent.object.finished_hour }}:{{ formatMinute(selectedEvent.object.finished_minute) }}
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="showModal = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    props: {
        month: Number,
        year: Number,
        weekends: Boolean,
        events: Array
    },
    data() {
        return {
            weekdays: ["Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sáb.", "Dom."],
            customDays: [],
            emptyCells: 0,
            showModal: false,
            selectedEvent: null
        };
    },
    computed: {
        filteredWeekdays() {
            return this.weekends ? this.weekdays : this.weekdays.slice(0, 5);
        },
        filteredDays() {
            return this.weekends ? this.customDays : this.customDays.filter(day => !this.isWeekend(day.date));
        }
    },
    mounted() {
        this.generateCalendar();
    },
    watch: {
        month: "generateCalendar",
        year: "generateCalendar",
        weekends: "generateCalendar",
        events: "generateCalendar"
    },
    methods: {
        formatMinute(minute) {
            return minute.toString().padStart(2, '0');
        },
        getFormattedDate(date) {
            const d = new Date(date);
            return d.getDate().toString().padStart(2, '0') + '/' + (d.getMonth() + 1).toString().padStart(2, '0') + '/' + d.getFullYear();
        },
        generateCalendar() {
            this.customDays = [];
            let startDate = new Date(this.year, this.month - 2, 21);
            let endDate = new Date(this.year, this.month - 1, 20);

            let firstDayOfWeek = startDate.getDay();
            this.emptyCells = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

            while (startDate <= endDate) {
                if (this.weekends || (!this.isWeekend(startDate))) {
                    this.customDays.push({
                        date: new Date(startDate),
                        day: startDate.getDate(),
                        events: []
                    });
                }
                startDate.setDate(startDate.getDate() + 1);
            }
            this.assignEventsToDays();
        },
        assignEventsToDays() {
            if (!this.events || this.events.length === 0) return;
            this.events.forEach(event => {
                let eventDate = new Date(event.start).toISOString().split("T")[0];
                let dayEntry = this.customDays.find(day => day.date.toISOString().split("T")[0] === eventDate);
                if (dayEntry) {
                    dayEntry.events.push(event);
                }
            });
        },
        openEventModal(event) {
            console.log(event)
            this.selectedEvent = event;
            this.showModal = true;
        },
        isWeekend(date) {
            const day = new Date(date).getDay();
            return day === 6 || day === 0;
        },
        getMonthName(month, extended = true) {
            const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
            return extended ? months[month - 1] : months[month - 1].slice(0, 3) + ".";
        }

    }
};
</script>

<style scoped>
.calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    border: 1px solid #ddd;
    overflow-x: auto; /* Permite scroll horizontal */
    min-width: 600px; /* Garante que não encolhe demasiado */
}
.calendar.no-weekends {
    grid-template-columns: repeat(5, 1fr);
}
.day-header {
    font-weight: bold;
    text-align: center;
}
.day-cell {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    min-height: 60px;
    position: relative;
    cursor: pointer;
    height: 100px;
    min-width: 80px; /* Evita que os dias fiquem demasiado pequenos */
}
.container {
    width: 100%;
    overflow-x: auto; /* Permite scroll horizontal */
}
.weekend {
    background: #f8d7da;
}
.empty {
    background: transparent;
    border: none;
}
.event {
    color: white;
    font-size: 12px;
    padding: 2px;
    margin-top: 5px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
}
.month-header {
    grid-column: span 7;
    text-align: center;
    font-weight: bold;
    color: red;
}
.month-label {
    color: #ff7f00;
    font-weight: bold;
}
</style>
