<template>
    <v-container fluid>
        <div>
            <!-- Componente de Upload -->
            <VueUploadMultipleImage
                ref="imageUploader"
                class="image-uploader"
                dragText="Inserir imagens"
                browseText="Clique aqui"
                :showPrimary="false"
                @upload-success="handleUploadSuccess"
                @before-remove="beforeRemove"
                :data-images="images"
                idUpload="myIdUpload"
                editUpload="myIdEdit"
                :maxImage="200"
                :disabled="locked_edition"
            />

            <!-- Botão para enviar todas as novas imagens -->
            <v-btn 
                v-if="method != 'create'"
                :class="{ 'shake': shakeButton }"
                style="margin: auto; display: block;"
                @click="uploadNewImages"
                class="warning mb-6"
                :disabled="newImages.length === 0"
            >
                Carregar imagens
            </v-btn>

            <DialogConfirmation title="Remover Imagem" type="warning" v-if="openConfirmationRemoveImage"
                :opened="openConfirmationRemoveImage" @on-submit="submitRemoveImage"
                @on-cancel="openConfirmationRemoveImage = false">
                Tem a certeza que pretende remover a imagem?
            </DialogConfirmation>
        </div>

        <Loader v-if="loaderAction"></Loader>  

        <DialogSuccess :opened="modalSuccess.show" @on-ok="modalSuccess.show = false;" >
            {{modalSuccess.message}}
        </DialogSuccess>

    </v-container>
</template>

<script>
import VueUploadMultipleImage from 'vue-upload-multiple-image';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Diary from '@/api/Diary'; // Certifica-te que a importação está correta
import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';
import Loader from '@/components/ui/Loader.vue';

export default {
    components: {
        VueUploadMultipleImage,
        DialogConfirmation,
        Loader,
        DialogSuccess
    },
    props: {
        images: Array,
        locked_edition: {
            type: Boolean,
            default: false
        },
        fields: Object,
        method: String
    },
    data() {
        return {
            modalSuccess:{
                show: false,
                message: null,
            },
            loaderAction: false,
            openConfirmationRemoveImage: false,
            newImages: [], // Apenas novas imagens adicionadas
            shakeButton: false, // Controla a animação do botão
        };
    },
    mounted() {
        // Define o intervalo para a animação do botão a cada 5 segundos
        setInterval(() => {
            if (this.newImages.length > 0) { // Apenas se houver imagens para carregar
                this.shakeButton = true;
                setTimeout(() => {
                    this.shakeButton = false;
                }, 1000); // Duração da animação (1 segundo)
            }
        }, 5000);
    },
    methods: {        
        beforeRemove(index, done, fileList) {
            if (this.locked_edition) return;

            this.openConfirmationRemoveImage = true;
            this.fileIndex = index;
            this.fileList = fileList;
        },
        handleUploadSuccess(imageData) {
            console.log("Imagem recebida no upload-success:", imageData);
            this.newImages.push(imageData); // Adiciona a imagem à lista
        },
        submitRemoveImage() {
            this.openConfirmationRemoveImage = false;

            let image = this.images[this.fileIndex].id;
            Diary.deleteImage(image, this.fields.id).then(() => {
                this.images.splice(this.fileIndex, 1);
            }).catch(err => {
                console.error("Erro ao remover imagem:", err);
            });
        },
        async uploadNewImages() {
            if (this.newImages.length === 0) return;

            this.loaderAction = true;
            
            try {
                const chunkSize = 3;
                for (let i = 0; i < this.newImages.length; i += chunkSize) {
                    const chunk = this.newImages.slice(i, i + chunkSize);
                    const formData = new FormData();

                    chunk.forEach((imageData, index) => {
                        for (const pair of imageData.entries()) {
                            formData.append(`images[${index}]`, pair[1]);
                        }
                    });

                    console.log(`Enviando chunk ${i / chunkSize + 1}:`, [...formData.entries()]);
                    
                    await Diary.createImage(formData, this.fields.id);
                }

                this.loaderAction = false;
                this.modalSuccess.message = "Imagens carregadas com sucesso";
                this.modalSuccess.show = true;
                this.newImages = [];
                this.$refs.imageUploader.clearAll();
               
                
            } catch (error) {
                console.error("Erro ao enviar imagens:", error);
                this.loaderAction = false;
            }
        }
    }
};
</script>

<style>
/* Animação de balanço estilo sino */
@keyframes shake {
    0% { transform: rotate(0); }
    25% { transform: rotate(-10deg); }
    50% { transform: rotate(10deg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(5deg); }
}

.shake {
    animation: shake 0.5s ease-in-out alternate 3; /* Faz 3 ciclos de balanço */
}
</style>
