<template>
    <validation-observer ref="form" v-slot="{ invalid }">
      <v-form @submit.prevent="submit"  class="mb-16">
         <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
        <fieldset :disabled="loading">
          <v-row v-if='fields.approved === "P" && method == "update" && !editMode && $root.session.hasPermission(["super", "holidays.approve"])'>
            <v-col cols="12" align="end" justify="end">
              <v-row>
                <v-col cols="12" md="12" align="end">
                  <IconApprove @on-submit="approvar" :big="true"/>
                  <IconReject @on-submit="rejeitar" :big="true"/>
                </v-col>      
              </v-row>
            </v-col>
          </v-row>
          <v-row  v-else-if="fields.approved != 'P'" align="start" justify="start">
            <v-col cols="12" md="4"></v-col>
            <v-col cols="8" md="2" align="start" justify="start">
              <v-alert :type="fields.approved == 'N' ? 'error' : 'success'">
                {{ fields.approved == 'N' ? 'Pedido rejeitado' : 'Pedido aprovado'}}
                <div v-if="fields.approved == 'N'">
                  <span class="text-caption">{{fields.justification}}</span>
                </div>
              </v-alert>
            </v-col>

              <!-- Botão de desaprovar só se estiver aprovado -->
            <v-col v-if="fields.approved == 'S' && $root.session.hasPermission(['super', 'holidays.edit'])" cols="4" md="1" align="start" justify="start">
              <v-btn 
                class="mt-3"
                color="red" 
                @click="showDisapproveDialog = true"
                dense
                outlined
                icon
                small
              >
                <v-icon>mdi-alert-circle</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="5"></v-col>

          </v-row>

            <!-- Modal de confirmação da desaprovação -->
            <v-dialog v-model="showDisapproveDialog" max-width="400px">
              <v-card>
                <v-card-title class="headline">Desaprovar</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-4">
                  Tem certeza que deseja desaprovar este pedido?
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                  <v-btn icon color="red" @click="showDisapproveDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                  <v-btn icon color="green" @click="desaprovar"><v-icon>
                    mdi-check
                  </v-icon></v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

          <v-row>
            <v-col
                cols="12"
                md="4"
                align="center"
            >
                <validation-provider v-slot="{ errors }" vid="intervalo" name="intervalo" rules="required">
                  <v-date-picker
                    :disabled="fields.approved != 'P'"
                    :error-messages="errors"
                    v-model="fields.interval"
                    range
                    :allowed-dates="allowWeekdays"
                  ></v-date-picker>
                </validation-provider>
            </v-col>
            <v-col cols="12" md="8">
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    disabled
                    label="Estado"
                    :value="getEstado(fields)"
                    dense outlined
                  />
                </v-col>    
                <v-col cols="12" md="5" v-if = "$root.session.hasPermission(['super', 'holidays.userDept']) && method == 'create'">
                  <v-select
                    :disabled="!$root.session.hasPermission(['super', 'holidays.userDept'])"
                    label="Pedido de"
                    :items="fields.usersDept"
                    item-text="name"
                    item-value="id"
                    v-model="fields.user_id"
                    dense outlined
                  />
                </v-col> 
                <v-col cols="12" md="5" v-if = "method == 'update' || (method == 'create' && !$root.session.hasPermission(['super', 'holidays.userDept']))">
                  <v-text-field
                    disabled
                    label="Pedido de"
                    :value="fields.user ? (fields.user.name || fields.user) : $root.session.name"
                    dense outlined
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    disabled
                    label="Pedido a"
                    :value="fields.created_at"
                    dense outlined
                  />
                </v-col>     
                <v-col cols="12" md="2">
                  <v-text-field
                    disabled
                    label="Dias úteis"
                    :value="fields.total_days"
                    dense outlined
                  />
                </v-col>   
              </v-row>

              
              <v-row>
              <v-col cols="12" md="12">
                <validation-provider v-slot="{ errors }" vid="motivo" name="Motivo" rules="required">
                  <v-select
                    :disabled="fields.approved != 'P'"
                    :items="motivos"
                    item-text="reason"
                    item-value="id"
                    v-model="fields.justification"
                    label="Motivo"
                    :error-messages="errors"
                    dense outlined
                  ></v-select>
                </validation-provider>
              </v-col>
            </v-row>


              <v-row>
                <v-col cols="12" md="12">
                  <validation-provider v-slot="{ errors }" vid="Descrição" name="Descrição" rules="|">
                    <v-textarea
                        :readonly="fields.approved != 'P'"
                        dense outlined                    
                        v-model="fields.description"
                        label="Descrição"
                        :error-messages="errors"
                      />
                    </validation-provider>
                </v-col>
              </v-row>

            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around" v-if="fields.approved === 'P' || editMode">
            <v-col align="center" justify="space-around">
              <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                Gravar
              </v-btn>
            </v-col>
          </v-row>
        </fieldset>
      </v-form>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
          Pedido efetuado com sucesso!
      </DialogSuccess>
    </validation-observer>
  </template>
  <script>
  import Holiday from "@/api/Holidays.js";
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import IconApprove from '@/components/ui/IconApprove.vue';
  import IconReject from '@/components/ui/IconReject.vue';
  import CalendarEvents from '@/api/CalendarEvents.js'
  
  export default {
    components: {
      DialogSuccess,
      IconApprove,
      IconReject,

    },
    props: {
     holiday: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
      holiday: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;


          this.fields.approved = data.approved

          //this.calculateValidDays(new Date(this.fields.start_time), new Date(this.fields.end_date))
        }
      },
      'fields.interval': {
        immediate: true,
        async handler(newVal) {
          if(newVal == null){
            this.fields.total_days = 0
            return
          }
          if (newVal.length < 2) {
            this.fields.total_days = 0; // Se o intervalo não for válido, reseta para 0
            return;
          }

          let start = newVal[0]
          let end = newVal[1]
          // Verifica se a data inicial é maior que a final, e inverte se necessário
          if (start > end) {
            [start, end] = [end, start]; // Inverte as datas
          }
          await this.fillBaseData()
          this.fields.total_days = this.calculateValidDays(new Date(start), new Date(end));
        },
      },
      fields: {
        deep: true,
        handler() {
          if (this.fields.approved !== 'P') {
            this.editMode = true;
          }
        },
      },
      showDisapproveDialog(val) {
        if (!val) {
          this.showDisapproveDialog = false;
        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
      feriados_empresa: [],
      motivos: [],
      showDisapproveDialog: false,
      editMode: false,
      fields: {
        id: null,
        interval: null,
        description: null,
        approved: 'P',
        total_days: null,
        user_id: null,
        evaluated_by: null,
        created_at: new Date().toISOString().split('T')[0],
        justification: null,
        usersDept: null,
        user: null,
      },
      loading:false,
      error: {
        title: '',
        message: '',
      },
      success: false,
    }),
    mounted(){
      this.fillBaseData();
      this.fetchMotivos();
      this.usersDept();
    },

    methods:{
      calculateValidDays(startDate, endDate) {
        let totalDays = 0;

        // Itera pelos dias no intervalo
        for (let current = new Date(startDate); current <= endDate; current.setDate(current.getDate() + 1)) {
          const date = new Date(current); // Cria uma nova instância para evitar alterações no original

          // Verifica se o dia é válido (dia útil e não feriado)
          const isWeekday = date.getDay() !== 0 && date.getDay() !== 6;
          const formattedDate = date.toISOString().split('T')[0];
          const isHoliday = this.feriados_empresa.includes(formattedDate);

          if (isWeekday && !isHoliday) {
            totalDays += 1;
          }
        }

        return totalDays;
      },
      async fillBaseData(){
        await CalendarEvents.list().then((resp) =>  {
          resp.data.forEach(element => {
            this.feriados_empresa.push(element.day_event)
          });
        })
        
      },

      async fetchMotivos(){
        try{
        await Holiday.motivos().then((resp) =>  {
          this.motivos = resp.data
        })
      }catch(e){
        console.log("Erro a obter motivos: ", e)
      }
      },

      async usersDept(){
        console.log("ESTOU AQUI+++++++");
        try {
          if(this.method == 'create' && this.$root.session.hasPermission(['super', 'holidays.userDept'])) {
            const resp = await Holiday.usersDept();
            this.fields.usersDept = resp.data;
            console.log("usersDept ++", this.fields.usersDept);
            this.fields.user_id = this.$root.session.id;
          } else {
            this.fields.user_id = this.$root.session.id;
            console.log("usersDept --", this.fields.usersDept);
          }
        } catch (error) {
          console.error("Erro a obter user do departamento:", error);
        }
      },

      getEstado(item){
        if(this.method == 'create') return 'Pendente';

       // console.log(item)
        if(item.approved == 'P')
          return 'Pendente';
        if(item.approved == 'N')
          return 'Rejeitado';
        
        return 'Aprovado';
      },
      approvar(){
        Holiday.approve(this.fields.id).then(() =>  {
          window.location.reload()
        })
      },
      rejeitar(e){
        Holiday.reject(this.fields.id, e).then(()  =>  {
          window.location.reload()
        })
      },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        this.$router.push('/admin/holidays');
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }

          if (!this.$root.session.hasPermission(['super', 'holidays.userDept'])) {
            this.fields.user_id = this.$root.session.id;
          }

          console.log("USER A ENIAR PARA BACKEND", this.fields.user_id);
  
          Holiday[this.method](this.fields, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;

            this.editMode = false;

            
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = "Erro " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      },

      async desaprovar() {

        this.loading = true;

        this.showDisapproveDialog = false;

        try {
          await Holiday.disapprove(this.fields.id);
          this.fields.approved = "P"; // Atualiza no frontend
          //this.$forceUpdate(); // Força atualização do Vue
          this.editMode = true;

          this.$nextTick(() => {
            this.$root.$emit('showSuccess', 'Pedido foi desaprovado.');
          });
        } catch (error) {
          this.$root.$emit('showError', 'Erro ao desaprovar o pedido.');
          console.error("Erro ao desaprovar:", error);
        } finally {
          this.loading = false;
        }
      },
    },
    computed: {
      allowWeekdays() {
        return (date) => {
          // Converte o valor para um objeto Date
          const dateObj = new Date(date);

          // Verifica se a data é válida
          if (isNaN(dateObj)) {
            return false; // Se não for uma data válida, retorna false
          }

          // Permite apenas de segunda a sexta-feira (dias úteis)
          const day = dateObj.getDay();
          const isWeekday = day !== 0 && day !== 6; // 0 = domingo, 6 = sábado

          // Verifica se a data está no array de feriados
          const formattedDate = dateObj.toISOString().split('T')[0]; // Formata como 'YYYY-MM-DD'
          const isHoliday = this.feriados_empresa.includes(formattedDate);

          // Permite apenas se for dia útil e não estiver no array de feriados
          return isWeekday && !isHoliday;
        };
      }
    }
  };
  </script>
  <style>
  .headline{
    background-color: darkblue;
    color: white;
  }
  </style>
  