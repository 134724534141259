<template>
  <v-app>

    <div class="bg">
    </div>
    <v-main>
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <v-card
              elevation="24"
              shaped
              style="background-color: rgba(0, 0, 0, 0.5);"
            >
            <div class="d-flex justify-center pb-8">
              <v-img
              lazy-src="/gpd_logo.png"
              src="/gpd_logo.png"
              max-width="250"
              ></v-img>
            </div>
            <h1 class="h1 pb-8 text-center" style="color:white;">Bem vindo de volta</h1>
            <validation-observer
              ref="login"
              v-slot="{ invalid, dirty }"
            >
              <v-form @submit.prevent="submit">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                    <validation-provider name="Email\Username" vid="username" rules="required" v-slot="{ errors }">
                      <v-text-field class="text-white" v-model="username" label="Email\Username" prepend-inner-icon="mdi-account" :error-messages="errors"></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                    <validation-provider name="Password" vid="password" rules="required" v-slot="{ errors }">
                      <v-text-field class="text-white" v-model="password" type="password" label="Password" prepend-inner-icon="mdi-lock" :error-messages="errors"></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                    <v-btn
                      depressed
                      color="primary"
                      class="mr-4"
                      type="submit"
                      :disabled="invalid && !dirty"
                      large
                      block
                    >
                      Login
                    </v-btn>

                    <v-btn
                      class="mt-4 mb-n4 font-weight-bold center-button"
                      rounded
                      block
                      style="background-color: #ffffff !important; opacity: 0.7;"
                      color="#111b2d"
                      @click="microsoftLogin"
                    >
                      <v-icon>mdi-microsoft</v-icon>
                      {{ $msal.isAuthenticated() ? user.userName : 'Usar conta Microsoft' }}
                    </v-btn>
                    <v-col
                      cols="12"
                      class="mt-4">
                        <v-btn
                          rounded
                          depressed
                          small
                          style="display: block; margin: auto;"
                          @click="$msal.signOut()"
                          v-if="$msal.isAuthenticated()"
                        >
                          <v-icon>mdi-logout</v-icon>
                          Logout
                        </v-btn>
                      </v-col>

                    <v-btn
                      text
                      color="white"
                      class="mr-4 mt-4 text-decoration-underline"
                      type="button"
                      block
                      @click="reset_password.opened = !reset_password.opened"
                    >
                      Esqueci-me da password
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </validation-observer>
            <div class="d-flex justify-center mb-5 mt-5">
              <v-img
              lazy-src="@/assets/geonorte.png"
              src="@/assets/geonorte.png"
              max-width="150"
              ></v-img>
            </div>

            <div class="d-flex justify-center">
              <span class="body-2 mr-3" style="color:white;">Powered By <a target="_blank" href="https://www.dreamdealtech.pt/">Dreamdeal Technologies</a></span>

              <v-img v-if="false"
              lazy-src="@/assets/logo_dream.png"
              src="@/assets/logo_dream.png"
              max-width="80"
              ></v-img>
            </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
      <v-dialog
        v-model="reset_password.opened"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="h5">Reset Password</span>
          </v-card-title>
          <v-card-text>
            <validation-observer v-if="!reset_password.reseted" ref="reset" v-slot="{ invalid }">
              <v-form @submit.prevent="reset">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <validation-provider v-slot="{ errors }" vid="email" name="Email" rules="required|email">
                        <v-text-field
                          v-model="reset_password.email"
                          prepend-inner-icon="mdi-at"
                          label="Email"
                          type="email"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-col align="center" justify="space-around">
                      <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                        Enviar email
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </validation-observer>
            <v-container v-else>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                  <h2 class="success--text text-center">Email enviado!</h2>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-icon
                      large
                      class="text-center"
                      color="success"
                    >
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="reset_password.opened = false"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-if="showMaintenance"
          v-model="showMaintenance"
          max-width="350px"
        >
          <v-container fluid class="ma-0 pa-0">
            <v-card class="elevation-0 ma-0 pa-0">
              <v-card-title class="primary white--text">
                Site em Manutenção
              </v-card-title>
              <v-card-text class="mt-2 text-center">
                O seu login não será bem sucedido enquanto o site estiver em manutenção. Agradecemos a sua compreensão!
              </v-card-texT>
              <v-card-text class="justify-center">
                <v-avatar height="105" width="300" tile style="margin: auto; display: block;">
                <v-img
                    src="@/assets/Maintenance/manutencao.png"
                    lazy-src="@/assets/Maintenance/manutencao.png"
                    contain
                >
                  </v-img>
                </v-avatar>
              </v-card-text>
              <v-card-text class="text-center">
                Tentaremos ser o mais breves possível!
              </v-card-text>
            </v-card>
          </v-container>
        </v-dialog>
  </v-app>
</template>
<style>
 .text-white input {
    color: white !important;
  }
.text-white label {
  color: white !important;
}
.text-white i {
  color: white !important;
}
.text-white v-messages__wrapper{
  color: white !important;
}
.bg {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    background: url( '../assets/imagem_login.jpg') no-repeat center center;
    background-size: cover;
    background-color: red;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    transform: scale(1.1);
  }
  </style>
<script>
import User from "@/api/User.js"
import System from "@/api/System.js"
import { msalMixin } from 'vue-msal';

export default {
  components: {
  },
  mixins: [msalMixin],
  data: () => ({
    username: "",
    password: "",
    reset_password: {
      opened: false,
      email: "",
      reseted: false,
    },
    showMaintenance: false,
  }),
  beforeCreate(){
    document.title = "GPD - Login";

    if(localStorage['auth'] == 1){
      this.$router.push('/admin');
    }
  },
  mounted(){
    System.getStatus().then((resp)  =>  {
      console.log(resp)
      this.showMaintenance = resp.data.value == "0" ? false : true
    })
    let cookieLogin = this.$cookies.get('login-try');
    let cookie = this.$cookies.get('reportlake_portal_cookie');

    if(cookie == null)
        this.showCookieBar = true

    if(cookieLogin){
      if(this.$msal.isAuthenticated()){
        User.microsoftLogin(this.$msal.data.user.userName, this.$msal.data.user.idToken.oid)
            .then(({data}) => {

                if(data.two_factor){
                  this.$router.push('/2fa');

                  return;
                }

                localStorage['auth'] = 1;

                this.$cookies.remove('login-try');

                this.$router.push('/admin');
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.login.setErrors(err.response.data.errors);

                return;
              }
          });
        }
    }
  },
  methods:{
    async microsoftLogin(){
      this.$cookies.set('login-try', true);

      if(this.$msal.isAuthenticated()){
        User.logout(this.$root.session != undefined ? this.$root.session.id : null)
            .then(() => {
            }).catch(() => {
            })
        User.microsoftLogin(this.$msal.data.user.userName, this.$msal.data.user.idToken.oid)
            .then(({data}) => {

              if(data.two_factor){
                this.$router.push('/2fa');

                return;
              }

              localStorage['auth'] = 1;

              this.$cookies.remove('login-try');


              this.$router.push('/admin');
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.login.setErrors(err.response.data.errors);

                return;
              }
          });
        }else{
          this.$msal.signIn()
        }
    },
    submit () {
        this.$refs.login.validate().then((valid) => {
          if (!valid) {
            return;
          }

          User.logout()
            .then(() => {
              this.login();
            }).catch(() => {
              this.login();
            })
        });
      },
      login(){
        let pwd = this.$encryptValue(this.password);
        User.login(this.username, pwd)
          .then(({data}) => {

              if(data.two_factor){
                this.$router.push('/2fa');

                return;
              }

              localStorage['auth'] = 1;
              // Obter a rota para redirecionar após o login ou redirecionar para a página padrão '/admin'
              let redirectPath = localStorage.getItem('redirectTo') || '/admin';

              // Se o user estiver à espera de registar QR Code, deve ir para a rota do qrcode-register
              if(localStorage['qrcode-register'] != undefined){
                redirectPath = localStorage['qrcode-register'];
                localStorage.removeItem('qrcode-register');
              }


              // Redirecionar para a página de destino
              this.$router.push(redirectPath);

              // Limpar o valor de 'redirectTo' após o login
              localStorage.removeItem('redirectTo');
          }).catch(err => {

            if(err.response.status == 422) {

              this.$refs.login.setErrors(err.response.data.errors);

              return;
            }
        });
      },
      reset () {
        this.$refs.reset.validate().then((valid) => {
          if (!valid) {
            return;
          }

          User.reset(this.reset_password.email)
            .then(() => {

                this.reset_password.reseted = true;
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.reset.setErrors(err.response.data.errors);

                return;
              }

              this.reset_password.reseted = false;

          });
        });
      },
  },
  computed:{
    user() {
        let user = null;
        if (this.msal.isAuthenticated) { // Note that the dollar sign ($) is missing from this.msal
          user = {
            ...this.msal.user,
            profile: {}
          }
          if (this.msal.graph && this.msal.graph.profile) {
              user.profile = this.msal.graph.profile
          }
        }

        return user;
      }
  }
};
</script>
