var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-16"},[_c('v-breadcrumbs',{staticClass:"pl-0",attrs:{"large":"","items":_vm.crumbs,"divider":"/"}}),_c('h1',{staticClass:"primary--text"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-shield-account")]),_vm._v(" "),_c('span',[_vm._v("Gestão de Entradas")])],1),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-forward")]),_vm._v(" Visualizar "),_c('v-icon',{staticClass:"ml-4",attrs:{"color":"red"}},[_vm._v(" mdi-delete ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-forward")]),_vm._v(" Remover "),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.works,"item-value":"id","item-text":function (item){ return item.code + ' - ' + item.name; },"dense":"","outlined":"","clearable":"","label":"Obra"},on:{"change":_vm.changedWork},model:{value:(_vm.filter.work_id),callback:function ($$v) {_vm.$set(_vm.filter, "work_id", $$v)},expression:"filter.work_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.showFrontsFilter)?_c('v-autocomplete',{attrs:{"items":_vm.work_fronts,"item-value":"id","item-text":function (item){ return item.reference + ' - ' + item.description; },"dense":"","outlined":"","clearable":"","label":"Frente Obra"},model:{value:(_vm.filter.front_id),callback:function ($$v) {_vm.$set(_vm.filter, "front_id", $$v)},expression:"filter.front_id"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Data","prepend-icon":"mdi-calendar","dense":"","outlined":"","readonly":""},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{on:{"input":function($event){_vm.datePicker= false}},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"success",attrs:{"fab":"","small":""},on:{"click":_vm.searchQRCodes}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-magnify")])],1)],1)],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-data-table',{staticClass:"mt-16",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.work",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.work.code)+" - "+_vm._s(item.work.name)+" ")]}},{key:"item.front",fn:function(ref){
var item = ref.item;
return [(item.front_id != null)?_c('div',[_vm._v(_vm._s(item.front.reference)+" - "+_vm._s(item.front.description))]):_vm._e()]}},{key:"item.worker",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.worker.code)+" - "+_vm._s(item.worker.name)+" ")]}},{key:"item.entry",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hora_entrada)+":"+_vm._s(item.minuto_entrada)+" ")]}},{key:"item.exit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSaida(item))+" ")]}},{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at)+" ")]}},(_vm.$root.session.hasPermission(['super', 'qrcode_workers.delete']))?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('IconRemove',{staticClass:"mr-2",on:{"on-submit":function($event){return _vm.deleteRegistry(item)}}})],1)],1)]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }