<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-shield-account</v-icon> <span>Gestão de Entradas</span>
        </h1>
        <v-icon color="primary">
          mdi-eye
        </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
        Visualizar
  
        <v-icon color="red" class="ml-4">
          mdi-delete
        </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
        Remover

        <v-row class="mt-4">
          <v-col cols="12" md="4">
            <v-autocomplete
              :items="works"
              item-value="id"
              :item-text="item=>item.code + ' - ' + item.name"
              dense outlined
              clearable
              label="Obra"
              v-model="filter.work_id"
              @change="changedWork"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-if="showFrontsFilter"
              :items="work_fronts"
              item-value="id"
              :item-text="item=>item.reference + ' - ' + item.description"
              dense outlined
              clearable
              label="Frente Obra"
              v-model="filter.front_id"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
                v-model="datePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="filter.date"
                        clearable
                        label="Data"
                        prepend-icon="mdi-calendar"
                        dense outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                v-model="filter.date"
                @input="datePicker= false"
                ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn class="success" fab small @click="searchQRCodes">
              <v-icon small>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
  
        <div style="position:relative">  
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            class="mt-16"
            locale="pt-pt"
          >
            <template v-slot:item.work="{item}">
              {{item.work.code}} - {{item.work.name}}
            </template>
            
            <template v-slot:item.front="{item}">
              <div v-if="item.front_id != null">{{item.front.reference}} - {{item.front.description}}</div>
            </template>

            <template v-slot:item.worker="{item}">
              {{item.worker.code}} - {{item.worker.name}}
            </template>

            <template v-slot:item.entry="{item}">
              {{item.hora_entrada}}:{{item.minuto_entrada}}
            </template>

            <template v-slot:item.exit="{item}">
              {{getSaida(item)}}             
            </template>

            <template v-slot:item.data="{item}">
              {{item.created_at}}
            </template>

            <template v-slot:item.actions="{item}" v-if="$root.session.hasPermission(['super', 'qrcode_workers.delete'])">
                <v-row>
                  <v-col cols="12" md="auto">
                    <IconRemove
                      class="mr-2"
                      @on-submit="deleteRegistry(item)"
                    />
                  </v-col>
                </v-row>
            </template>
         
          </v-data-table>
        </div>
    </section>
  </template>
  <script>
  //import IconRemove from '@/components/ui/IconRemove.vue';
  //import IconView from '@/components/ui/IconView.vue';
  import QRcode from "@/api/QRCode.js";
  import Work from '@/api/Work.js'
  import IconRemove from '@/components/ui/IconRemove.vue';
  
  export default {
    components: {
      IconRemove,
      //IconView
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchQRCodes()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "GPD - Gestão de Entradas";
  
   
    },
    data: () => ({
      datePicker: false,
      work_fronts:[],
      showFrontsFilter: false,
      headers: [
        {
          text: 'Obra',
          sortable: true,
          value: 'work',
        },
        {
          text: 'Frente',
          sortable: true,
          value: 'front',
        },
        {
          text: 'Colaborador',
          sortable: true,
          value: 'worker',
        },
        {
          text: 'Entrada',
          sortable: true,
          value: 'entry',
        },
        {
          text: 'Saída',
          sortable: true,
          value: 'exit',
        },
        {
          text: 'Data',
          sortable: true,
          value: 'data',
        },
        { 
          text: 'Opções', 
          value: 'actions', 
          sortable: false 
        },
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      filter: {
        name: null,
        work_id: null,
        front_id: null,
        no_date: 'no',
        date: null,
      },
      works:[]
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "qr_codes.read"])) {
        this.$router.push('/no-permission');
      }
  
      this.fillBaseData();
    },
    methods:{
      deleteRegistry(item){

        console.log("Registo a apagar", item.id);
        
        QRcode.delete(item.id)
          .then(() => {
            this.searchQRCodes();
          });
          
      },
      changedWork(item){        
        Work.find(item).then((resp) =>  {
          if(resp.data.work_fronts.length == 0){
            this.showFrontsFilter  = false
          }else{
            this.work_fronts = resp.data.work_fronts
            this.showFrontsFilter = true
          }
        })
      },
      getSaida(item){
        if(item.hora_saida == null)
          return '';

        return item.hora_saida + ':' + item.minuto_saida
      },
      fillBaseData() {
        
        if(localStorage["qr_codes-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["qr_codes-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }

        Work.list().then((resp) =>  {
          this.works = resp.data

          if(this.filter.work_id != null)
            this.changedWork(this.filter.work_id)
        })
      },
      searchQRCodes(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
        if(this.showFrontsFilter == false)
          this.filter.front_id = null;
  
        let filter = {...this.filter};
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["qr_codes-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        QRcode.search(request).then(response => {
          
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },
      resetQRCode()
      {
        this.filter = {};
  
        this.searchQRCodes();
      },
      viewQRCode(item){
        this.$router.push('/admin/settings/roles/' + item.id);
      },
      QRcode(item){
        QRcode.delete(item.id)
          .then(() => {
            this.searchRoles();
          });
      },
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Gestão de Entradas',
            disabled: true,
            to: window.location.href,
            exact: true,
          },

        ];
      }
    }
  };
  </script>
  