import BaseApi from "./BaseApi";

export default class QRCode extends BaseApi{
    construct() {}

    static async getData(params){
        let url = QRCode.getApiUrl() + 'get-mo-data'


        return await QRCode.getAxiosInstance().get(url,{params: params});
    }

    static async search(params) {
        return await QRCode.getAxiosInstance().get(QRCode.getApiUrl(),{params: params});
    }

    static async getPicagens(params) {
        return await QRCode.getAxiosInstance().get(QRCode.getApiUrl() + 'get-picages',{params: params});
    }

    static async create(params) {
        return await QRCode.getAxiosInstance().post(QRCode.getApiUrl(),params);
    }

    static async registerExit(params) {
        return await QRCode.getAxiosInstance().post(QRCode.getApiUrl() + 'exit',params);
    }

    static async delete(id) {
        return await QRCode.getAxiosInstance().delete(QRCode.getApiUrl() + 'delete/' + id);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/qrcode/";
    }
}