<template>
  <validation-observer ref="form" v-slot="{ invalid }">
    <v-form @submit.prevent="submit"  class="mb-16">
       <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>
      <fieldset :disabled="loading">        
        <v-row>
          <v-col cols="12" md="12">
            <v-switch
              v-model="fields.is_active"
              color="success"
              :true-value="1"
              :false-value="0"
              label="Ativo"
              hide-details
            ></v-switch>
          </v-col>
          <v-col cols="12" md="6">
            <v-radio-group prepend-icon="mdi-compare" label="Tema" v-model="fields.theme" @change="changeTheme" row>
              <v-radio v-for="theme in $themes" v-bind:key="theme.name" :label="theme.name" :value="theme"></v-radio>
            </v-radio-group>
            <h3></h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
              <v-text-field
                v-model="fields.name"
                dense outlined
                prepend-inner-icon="mdi-account-details"
                label="Nome *"
                clearable
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="phone_number" name="Telefone" rules="|">
              <v-text-field
                v-model="fields.phone_number"
                dense outlined
                prepend-inner-icon="mdi-phone"
                label="Telefone"
                clearable
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="email" name="Email" rules="required|email">
              <v-text-field
                v-model="fields.email"
                dense outlined
                prepend-inner-icon="mdi-at"
                label="Email *"
                clearable
                type="email"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="username" name="Username" rules="required">
              <v-text-field
                v-model="fields.username"
                dense outlined
                prepend-inner-icon="mdi-account-details"
                label="Username *"
                clearable
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row v-if="$root.session.id != fields.id">
          <v-col cols="12">
            <validation-provider v-slot="{ errors }" vid="roles" name="Roles" rules="required">
              <multiple-select
                :singleSelect="true"
                v-model="fields.roles"
                dense outlined
                :items="roles"
                item-text="display_name"
                item-value="id"
                menu-props="auto"
                label="Roles *"
                prepend-inner-icon="mdi-shield-account"
                :error-messages="errors"
              ></multiple-select>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              dense outlined
              :item-text="item => item.code + ' - ' + item.name"
              prepend-inner-icon="mdi-account"
              v-model="fields.worker_id"
              item-value="id"
              :items="workers"
              label="Colaboradores"
              @change="colaboradorChanged"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-checkbox
              label="Alterar password?"
              v-model="changePassword"
            />
          </v-col>
          <v-col v-if="changePassword" cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="password" name="Password" :rules="fields.password != null ? 'required|small_letter|password_size|special_char_1|capital_letter|one_number' : ''">
              <v-text-field
                v-model="fields.password"
                dense outlined
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                label="Password"
                clearable
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <template v-if="$root.session.id == $route.params.id">
            <template v-if="qrcode">
              <v-col cols="12">
                <div v-html="qrcode"></div>
              </v-col>

              <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" vid="two_fa_code" name="Código 2FA" rules="required">
                  <v-text-field
                    v-model="fields.two_fa_code"
                    prepend-inner-icon="mdi-numeric"
                    label="Código 2FA *"
                    clearable
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </template>

            <v-col cols="12">
              <v-switch
                v-model="fields.has_2fa"
                color="success"
                @change="toggle2Fa"
                label="2FA"
                hide-details
              ></v-switch>
            </v-col>
          </template>
        </v-row>
        <!-- MICROSOFT IDENTIFIER -->
        <v-row v-if="canJoinAccount">
          <v-col cols="12">
            <v-btn
              v-if="fields.microsoft_identifier == null"
              class="mt-4"
              color="#6E7A90"
              dark
              @click="microsoftLogin"
            >
            <div v-if="!$msal.isAuthenticated()">
              <v-icon class="mr-2" >mdi-microsoft</v-icon>Registar conta Microsoft
            </div>
            <div v-else>
              <v-icon class="mr-2">mdi-microsoft</v-icon>Registar {{$msal.data.user.userName}}
            </div>
            </v-btn>
            <v-btn
              v-if="fields.microsoft_identifier != null"
              class="mt-4"
              disabled
              outlined
            >
              <v-icon class="mr-2">mdi-microsoft</v-icon>Conta Microsoft configurada
            </v-btn>
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="12" v-if="microsoftRegister">
            <v-btn
              v-if="fields.microsoft_identifier == null"
              class="mt-4"
              outlined
              @click="microsoftLogin"
            >
              <div v-if="!$msal.isAuthenticated()">
                <v-icon class="mr-2" >mdi-microsoft</v-icon>Registar conta Microsoft
              </div>
              <div v-else>
                <v-icon class="mr-2">mdi-microsoft</v-icon>Registar {{$msal.data.user.userName}}
              </div>
            </v-btn>
            <v-btn
              v-if="fields.microsoft_identifier != null"
              class="mt-4"
              disabled
              outlined
            >
              <v-icon class="mr-2">mdi-microsoft</v-icon>Conta Microsoft configurada
            </v-btn>
          </v-col>
          <v-col cols="12" class="mt-n4" v-if="fields.microsoft_identifier == null && microsoftRegister">                    
            <p color="primary"><small>Sair da conta microsoft em Logout para escolher outra, ou registe a sua conta atual clicando no botão acima</small></p>
          </v-col>
          
          <v-col cols="12" md="12" class="mt-n4" v-if="fields.microsoft_identifier == null  && microsoftRegister">
            <v-btn  
              outlined
              small
              @click="$msal.signOut()"
              v-if="$msal.isAuthenticated()"
            >
              <v-icon>mdi-logout</v-icon>
              Logout
            </v-btn>
          </v-col> 
        </v-row>
        <v-row v-if="manageWorks">
          <v-col cols="12" md="6">
            <v-card>
              <v-card-title class="primary white--text">
                Autorizações de Obra
              </v-card-title>
              <v-card-text class="mt-2">
                <!-- Autorizações de Obras -->
                <v-simple-table>
                  <thead>
                    <th>Obra</th>
                    <th>Opções</th>
                  </thead>
                  <tbody v-for="obra in fields.access_works" :key="obra.id">
                    <tr>
                      <td width="90%">
                        {{obra.code}} - {{ obra.name }}
                      </td>
                      <td>
                        <IconRemove
                            class="mr-2"
                            @on-submit="removeWork(obra)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col cols="12" md="11">
                    <v-autocomplete
                      :items="works"
                      label="Obras"
                      v-model="newWorkChoosed"
                      item-value="id"
                      dense outlined
                      :item-text="item=>item.code + ' - ' + item.name"
                    />
                  </v-col>
                  <v-col cols="12" md="1">
                    <v-btn :disabled="newWorkChoosed == null" x-small fab class="success" @click="addWork">
                      <v-icon x-small>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>


        <v-row class="mt-6" align="center" justify="space-around">
          <v-col align="center" justify="space-around">
            <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
              Gravar
            </v-btn>

            <v-btn v-if="!!user && !user.is_verified" depressed color="secondary" class="mr-5 ml-5 mb-5" type="button" @click="resendActivationEmail">
              Reenviar Email de Ativação
            </v-btn>

            <v-btn depressed color="error" class="mr-5 ml-5 mb-5" type="button" @click="reset">
              Reset Password
            </v-btn>

            
          </v-col>
        </v-row>
        <v-row v-if="false">
          <v-btn  depressed color="primary" class="mr-5 ml-5 mb-5" type="button" @click="manda">
            Partes diarias por fazer
          </v-btn>
          <v-btn  depressed color="primary" class="mr-5 ml-5 mb-5" type="button" @click="mandaPD">
              Partes diarias por aprovar
            </v-btn>
        </v-row>
      </fieldset>
    </v-form>
    <DialogSuccess :opened="success" @on-ok="onSuccess" >
      Utilizador gravado com sucesso!
    </DialogSuccess>
    <v-dialog
        v-model="password_reseted"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="h5">Reset Password</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                  <h2 class="success--text text-center">Email enviado!</h2>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-icon
                      large
                      class="text-center"
                      color="success"
                    >
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="password_reseted = false"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="activation_email_resent"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="h5">Email de Ativação</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                  <h2 class="success--text text-center">Email enviado!</h2>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-icon
                      large
                      class="text-center"
                      color="success"
                    >
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="activation_email_resent = false"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </validation-observer>
</template>
<script>
import Emails from "@/api/Emails.js";
import User from "@/api/User.js";
import Role from "@/api/Role.js";
import Work from "@/api/Work.js";
import Worker from "@/api/Worker.js";
import MultipleSelect from '@/components/ui/MultipleSelect.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import IconRemove from '@/components/ui/IconRemove.vue';
import { msalMixin } from 'vue-msal'; 

export default {
  components: {
    MultipleSelect,
    DialogSuccess,
    IconRemove
  },
  props: {
   user: Object,
   method: String,
   disabled: Boolean,
 },
 mixins: [msalMixin],
 watch: { 
  '$route.params.id'() {
      let parent = this
      User.find(this.$route.params.id)
        .then((r) => {
          parent.fields = r.data

          this.canRegister()
        })
    },
    user: {
      immediate: true, 
      handler (val) {
        this.fields.theme = this.$themes[0];

        if(!val) {
          return;
        }

        let data = {...val};

        data.roles = data.roles.map((item) => { return item.id});

        this.fields = data;

        this.fields.id = data.id;

        this.fields.theme = this.$themes.find((item) => data.theme == null ? false : item.name == data.theme.name)

        if(this.fields.theme == undefined || this.fields.theme.length == 0){
          this.fields.theme = this.$themes[0];
        }
      }
    },
  },
  beforeCreate(){
  },
  data: () => ({
    microsoftRegister: false,
    show1: false,
    changePassword: false,
    newWorkChoosed: null,
    fields: {
      id: null,
      name: null,
      username:null,
      email:null,
      phone_number:null,
      is_active:0,
      has_2fa: null,
      two_fa_code: null,
      roles:[],
      theme: null,
      microsoft_identifier: null,
      password: null,
      worker_id: null,
      access_works: []
    },
    qrcode:null,
    loading:false,
    error: {
      title: '',
      message: '',
    },
    roles: [],
    works: [],
    workers:[],
    password_reseted:false,
    activation_email_resent:false,
    success: false,
  }),
  async mounted(){
    await this.fillBaseData();

    this.canRegister()

    if(this.$route.name=='UserFromWorker'){
      Worker.find(this.$route.params.id).then((resp)    =>  {
        let ob = resp.data

        this.fields.name = ob.name
        this.fields.phone_number = ob.phone_number
        this.fields.worker_id = this.$route.params.id
      })
    }
  },
  methods:{
    addWork(){
      let work_ob = null
      this.works.forEach(element => {
        if(element.id == this.newWorkChoosed)
          work_ob = element;
      });

      if(work_ob == null)
        return

      this.fields.access_works.push(work_ob)

      this.newWorkChoosed = null
    },
    removeWork(obra){
      let pos = -1
      let i = 0
      this.fields.access_works.forEach(element => {
        if(element.id == obra.id)
          pos = i

        i++
      });

      if(pos == -1)
        return

      this.fields.access_works.splice(pos, 1)
    },
    colaboradorChanged(item){
      if(this.method == 'update')
        return

      let selected = null
      this.workers.forEach(element => {
        if(element.id == item)
          selected = element
      });

      this.fields.name = selected.name
      this.fields.phone_number = selected.phone_number
      this.fields.username = selected.name.toLowerCase().replace(/\s+/g, '.');


    },
    canRegister(){
      if(this.fields.id == this.$root.session.id)
        this.microsoftRegister = true

    },
    microsoftLogin(){
      if(this.$msal.isAuthenticated()){
        if(this.$msal.data.user.userName == this.$root.session.email){
          User.registerExternalLogin(this.$msal.data.user.userName, this.$msal.data.user.idToken.oid, 'microsoft', this.$root.session.id)
            .then(() => {

              this.$cookies.remove('addMicrosoftAccount');
              window.location.reload()
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.login.setErrors(err.response.data.errors);
                
                return;
              }
          });
        }
      }else this.$msal.signIn()
    },
    mandaPD(){
      Emails.diariesToApprove().then((resp) => {
        console.log(resp)
      });
    },
    manda(){
      Emails.diariesToDo().then((resp) => {
        console.log(resp)
      });
    },
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      this.$router.push('/admin/settings/users');
    },
    changeTheme(theme){
      this.setTheme(theme)

      this.fields.theme = theme;
    },
    fillBaseData(){
      Role.list().then(({data}) => {
        this.roles = data;
      });
      Worker.list().then(({data}) => {
        this.workers = data;
      });
      Work.list().then((resp) =>  {
        this.works = resp.data
      })
    },
    toggle2Fa()
    {
      if(this.fields.has_2fa){
          User.enable2FA()
          .then(() => {
            this.render2Fa()
          });

          return;
      }
      
      User.disable2FA()
          .then(() => {
            this.qrcode = null;
          });
    },
    render2Fa(){
      User.get2FA()
        .then(({data}) => {
          this.qrcode = data.svg;
        });
    },
    reset () {
      User.reset(this.user.email)
          .then(() => {

              this.password_reseted = true;
          });
    },
    resendActivationEmail() {
      User.resendActivationEmail(this.user.id)
        .then(() => {

            this.activation_email_resent = true;
        });
    },
    submit() {

      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        if(this.fields.password != undefined)
          this.fields.password = this.$encryptValue(this.fields.password)

        User[this.method](this.fields, this.fields.id).then(({data}) => {

          this.success = true;

          this.loading = false;

          if(data.id == this.$root.session.id){
            let user = User.getInstance(data);

            this.$root.session = user;

            return;
          }

          this.changePassword = false

          this.setTheme(this.$root.session.theme);
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
          this.fields.password = ''
      })
    }
  },
  computed: {
    manageWorks(){
      let id_encarregado = null
      let id_engenheiro = null
      this.roles.forEach(element => {
        if(element.name == 'encarregado')
          id_encarregado = element.id
        if(element.name == 'dto')
          id_engenheiro = element.id
      });
      let res = false
      this.fields.roles.forEach(element => {
        console.log(element)
        if(element == id_encarregado || element == id_engenheiro)
          res = true
      });
      return res
    },
    canJoinAccount(){
      if(Object.keys(this.$route.params).length == 0)
        return true

      return false
    }
  }
};
</script>
