import BaseApi from "./BaseApi";

export default class Departments extends BaseApi{
    construct() {}

    static async search(params) {
        return await Departments.getAxiosInstance().get(Departments.getApiUrl(),{params: params});
    }

    static async create(params) {
        return await Departments.getAxiosInstance().post(Departments.getApiUrl(),params);
    }

    static async update(params, id) {
        return await Departments.getAxiosInstance().put(Departments.getApiUrl() + id,params);
    }

    static async delete(id) {
        return await Departments.getAxiosInstance().delete(Departments.getApiUrl() + id);
    }

    static async find(id) {
        return await Departments.getAxiosInstance().get(Departments.getApiUrl() + id);
    }

    static async getUsersOfDept(id) {
        return await Departments.getAxiosInstance().get(Departments.getApiUrl() + "users-dept/" + id);
    }

    static async removeUserFromDept(params) {
        return await Departments.getAxiosInstance().post(Departments.getApiUrl() + "remove-user/dept", params);
    }

    static async addUserToDept(params) {
        return await Departments.getAxiosInstance().post(Departments.getApiUrl() + "dept/add-user", params);
    }

    static async getDeptUsers(params) {
        return await Departments.getAxiosInstance().post(Departments.getApiUrl() + "dept-users", {params: params});
    }

    static async getUsersWithoutDept() {
        return await Departments.getAxiosInstance().get(Departments.getApiUrl() + "users/users-without-dept");
    }

    static async list(params) {
        let url = Departments.getApiUrl() + "list";

        return Departments.getAxiosInstance().get(url,{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/departments/";
    }
}