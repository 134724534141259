<template>
    <validation-observer ref="form" v-slot="{ invalid }">
      <v-form @submit.prevent="submit"  class="mb-16">
         <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
        <fieldset :disabled="loading">
          <v-row align="center" justify="center">
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" vid="name" name="Departamento" rules="required">
                <v-text-field
                  dense outlined
                  v-model="fields.name"
                  prepend-inner-icon="mdi-account-details"
                  label="Departamento *"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="fields.holidays_approvers"
                :items="users"
                dense outlined
                item-value="id"
                :item-text="item=>item.name"
                label="Responsável"
              ></v-autocomplete>
            </v-col>
          </v-row>         
          <v-row>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="selectedUser"
                :items="computeUsers"
                item-value="id"
                :item-text="item => item.name"
                label="Adicionar Utilizador"
                dense outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="auto">
              <v-btn icon fab small @click="addUserToDepartment" class="ml-2 mb-2 btn-verde"><v-icon color="white">mdi-plus</v-icon></v-btn>
            </v-col>
            <v-col cols="12" md="12">
              <v-data-table
                v-if="fields.users.length > 0"
                :headers="userTableHeaders"
                :items="fields.users"
                item-key="id"
                class="elevation-1 table-cabecalho"
                header-color="primary"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td> {{ item.worker == null ? '-' : item.worker.code }} </td>
                    <td> {{ item.name }} </td>
                    <td>
                      <v-icon class="justify-end" @click="confirmDelete(item.id)" color="red">mdi-delete</v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-alert v-else>Sem Utilizadores neste Departamento.</v-alert>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="center">
            <v-btn :disabled="invalid" depressed color="primary" type="submit">
              Gravar
            </v-btn>
          </v-row>
        </fieldset>
      </v-form>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
        Departamento gravado com sucesso!
      </DialogSuccess>
      <v-dialog v-model="confirmDialog" max-width="500px">
        <v-card>
          <v-card-title class="headline">A remover do departamento...</v-card-title>
          <v-card-text class="pa-4">
            Tem a certeza que pretende remover este utilizador deste departamento?
          </v-card-text>
          <v-card-actions class="pa-4 justify-end">
            <v-btn outlined color="grey" @click="confirmDialog = false">Cancelar</v-btn>
            <v-btn outlined color="red" @click="removeUser">Remover</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </validation-observer>
  </template>
  <script>
  import DepartmentAPI from "@/api/Departments.js";
  import User from "@/api/User.js";
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';

  
  export default{
    name: "Department",
    components:{
        DialogSuccess
    },
    props: {
     department: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
    department: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
  
          this.fields.id = data.id;
        }
      },
    },
    beforeCreate(){
    },
    data: () => ({
      fields: {
        id: null,
        name: null,
        holidays_approvers: [],
        users:[]
      },
      loading:true,
      users:[],
      selectedUser: null,
      error: {
        title: '',
        message: '',
      },
      permissions: [],
      success: false,
      userTableHeaders: [
          { 
            text: "Código", 
            align: "start", 
            key: "code" 
          }, 
          { 
            text: "Nome", 
            align: "start", 
            key: "name" 
          },
          { 
            text: "", 
            align: "start", 
            key: "actions" 
          }    
        ],
      reloadUsers: 999,
      confirmDialog: false, 
      userToDelete: null, 
    }),
    mounted(){
      this.fillBaseData();
    },
    methods:{
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        this.$router.push('/admin/company-space/departments');
      },
      fillBaseData(){
        User.list().then((resp) =>  {
          this.users = resp.data
        });

        this.loading = false        
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
  
          DepartmentAPI[this.method](this.fields, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;
  
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = "Erro " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      },
      confirmDelete(userId) {
        this.userToDelete = userId;
        this.confirmDialog = true; 
      },
      removeUser() {
        if (this.userToDelete !== null) {
          let pos = -1
          let i = 0
          this.fields.users.forEach(element => {
            if(element.id == this.userToDelete)
              pos = i

            i++
          });

          if(pos != -1)
            this.fields.users.splice(pos, 1); // Remove 1 elemento no índice 'pos'
        }

        this.confirmDialog = false
      },
      addUserToDepartment() {
        console.log(this.selectedUser)
        this.users.forEach(element => {
          if(element.id == this.selectedUser)
            this.fields.users.push(element)
          
        });
      },
    },
    computed:{
      computeUsers(){
        let final_array = []
        this.users.forEach(user => {
          if(user.worker_id != null){
            let exists = false
            this.fields.users.forEach(element => {
              if(user.id == element.id)
                exists = true              
            });

            if(exists == false)
              final_array.push(user)
          }
        });
        return final_array
      }
    }
  };
  </script>

<style scoped>

.table-cabecalho .v-data-table-header {
  background-color: red; 
  color: white; 
}

.table-cabecalho .v-data-table-header th {
  font-weight: bold; 
}

.btn-verde {
  background-color: #4CAF50;

}
</style>
  