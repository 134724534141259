<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-shape</v-icon> <span>Gestão de Categorias Profissionais</span>
      </h1>
      <v-icon color="primary">
        mdi-eye
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Visualizar

      <v-icon color="red" class="ml-4">
        mdi-delete
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Remover

      <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Pesquisar</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  to="/admin/workers-settings/categories/create"
                  v-if="$root.session.hasPermission(['super', 'categories.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registar</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16"
          locale="pt-pt"
        >
          <template v-slot:item.value="{ item }">
            {{item.value.toFixed(2)}}€
          </template>
          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewCategory(item)"
            />
            <IconRemove
              v-if="!item.has_workers && $root.session.hasPermission(['super', 'categories.delete'])"
              class="mr-2"
              @on-submit="deleteCategory(item)"
            />
          </template>
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetCategories">
              Limpar Campos
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchCategories">
            
            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-form-textbox"
                label="Nome"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              Pesquisar
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
  </section>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import Category from "@/api/Category.js";
import dayjs from 'dayjs';

export default {
  components: {
    IconRemove,
    IconView
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchCategories()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "GPD - Gestão de Categorias Profissionais";
  },
  data: () => ({
    headers: [
      {
        text: 'Nome',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Custo',
        sortable: true,
        value: 'value',
      },
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      code:null,
      name: null,
    },
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "categories.read"])) {
      this.$router.push('/no-permission');
    }

    this.fillBaseData();
  },
  methods:{
    getTableDate(date) {
      return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
    },
    fillBaseData() {

      if(localStorage["categoriesv2-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["categoriesv2-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchCategories(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["categoriesv2-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Category.search(request).then(response => {

        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetCategories()
    {
      this.filter = {
      };

      this.searchCategories();
    },
    viewCategory(item){
      this.$router.push('/admin/workers-settings/categories/' + item.id);
    },
    deleteCategory(item){
      Category.delete(item.id)
        .then(() => {
          this.searchCategories();
        });
    },
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão Categprias',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
