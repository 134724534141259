import BaseApi from "./BaseApi";

export default class Holidays extends BaseApi{
    construct() {}
    
 
    static async search(params) {
        return await Holidays.getAxiosInstance().get(Holidays.getApiUrl(),{params: params});
    }

    static async delete(id) {
        return await Holidays.getAxiosInstance().delete(Holidays.getApiUrl() + id);
    }

    static async create(params) {
        return await Holidays.getAxiosInstance().post(Holidays.getApiUrl(),params);
    }

    static async update(params, id) {
        return await Holidays.getAxiosInstance().put(Holidays.getApiUrl() + id,params);
    }

    static async reject(id, e) {
        let params = {
            explanation: e
        }
        return await Holidays.getAxiosInstance().put(Holidays.getApiUrl() + id + '/reject', params);
    }

    static async approve(id) {
        return await Holidays.getAxiosInstance().put(Holidays.getApiUrl() + id + '/approve');
    }

    static async disapprove(id) {
        return await Holidays.getAxiosInstance().put(Holidays.getApiUrl() + id + '/disapprove');
    }

    static async saveNotes(data) {
        return await Holidays.getAxiosInstance().post(Holidays.getApiUrl() + 'save-notes', data);
    }

    static async find(id) {
        return await Holidays.getAxiosInstance().get(Holidays.getApiUrl() + id);
    }

    static async motivos() {
        return await Holidays.getAxiosInstance().get(Holidays.getApiUrl() + 'motivos/reason');
    }

    static async usersDept() {
        return await Holidays.getAxiosInstance().get(Holidays.getApiUrl() + 'others/get-user-by-dept');
    }

    static async list(params) {
        let url = Holidays.getApiUrl() + "list";

        return Holidays.getAxiosInstance().get(url,{params: params});
    }

    static async gantt(params) {
        let url = Holidays.getApiUrl() + "gantt";

        return Holidays.getAxiosInstance().get(url,{params: params});
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/holidays/";
    }
}