<template>
    <div>
      <v-row>
        <v-col cols="12" md="5">
          <v-select
            v-model="selectedYear"
            :items="years"
            label="Ano"
            dense outlined
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="selectedMonth"
            :items="months"
            label="Mês"
            dense outlined
            clearable
          ></v-select>
        </v-col>

        <v-col cols="12" md="1">
        <v-icon
          color="primary"
          @click="obtemScores"
          class="mt-3"
        >
          mdi-magnify
        </v-icon>
      </v-col>

      </v-row>    

      <v-data-table
        :headers="headers"
        :items="filteredScores"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-divider class="mx-4" inset vertical></v-divider>
            
            <span v-if="searchMonth">
            Scores de {{ getMonthName(searchMonth) }} de {{ searchYear }}
          </span>
          <span class="ml-2" v-else>
            Scores de {{ searchYear }}
          </span>

          <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon color="primary">
                mdi-chart-line
            </v-icon>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-switch
          class="mt-3"
          inset
          v-model="showDto"
          @change="filterData"
          label="DTOs"
        />

        <v-divider class="mx-4" inset vertical></v-divider>

        <v-switch
          class="mt-3"
          inset
          v-model="showEncarregado"
          @change="filterData"
          label="Encarregados"
        />

        <v-divider class="mx-4" inset vertical></v-divider>

          <v-spacer></v-spacer>
          </v-toolbar>
        </template>



      </v-data-table>
    </div>
  </template>
  
  <script>

    import Score from '@/api/Score';

  export default {
    data() {
      return {
        selectedYear: new Date().getFullYear(),
        selectedMonth: new Date().getMonth() + 1,
        searchYear: new Date().getFullYear(),
        searchMonth: null,
        showDto: true,
        showEncarregado: true,
        years: Array.from({ length: 3 }, (v, k) => new Date().getFullYear() - k),
        months: [
          { text: 'Janeiro', value: 1 },
          { text: 'Fevereiro', value: 2 },
          { text: 'Março', value: 3 },
          { text: 'Abril', value: 4 },
          { text: 'Maio', value: 5 },
          { text: 'Junho', value: 6 },
          { text: 'Julho', value: 7 },
          { text: 'Agosto', value: 8 },
          { text: 'Setembro', value: 9 },
          { text: 'Outubro', value: 10 },
          { text: 'Novembro', value: 11 },
          { text: 'Dezembro', value: 12 },
        ],
        headers: [
          { text: 'Nome', value: 'name' },
          { text: 'Categ. Prof.', value: 'category' },
          { text: 'Role', value: 'role' },
          { text: 'Score', value: 'percentage_score' },
        ],
        scores: [],
        filteredScores: [],
      };
    },


    methods: {
        async obtemScores() {
      try {
        const response = await Score.getScores(this.selectedYear, this.selectedMonth);
        this.scores = response.data;

        this.searchYear = this.selectedYear;
        this.searchMonth = this.selectedMonth;

        this.filterData();

      } catch (error) {
        console.error('Error fetching scores:', error);
      }
    },

    filterData() {
      this.filteredScores = this.scores.filter(item => {
        if (item.role === 'dto' && !this.showDto) {
          return false;
        }
        if (item.role === 'encarregado' && !this.showEncarregado) {
          return false;
        }
        if (item.role !== 'dto' && item.role !== 'encarregado') {
          return false;
        }
        return true;
      });
    },

    getMonthName(month) {
      const monthNames = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
      ];
      return monthNames[month - 1];
    }

    },

    mounted() {
      this.obtemScores();
    },
  };
  </script>
  
