<template>
    <v-row justify="center">
        <v-col cols="12" md="6" align="center">
            <v-card>
                <v-card-title class="primary white--text">
                    Informação de registo
                </v-card-title>
                <v-card-text class="mt-2">
                    {{message}}
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import QRCode from '@/api/QRCode.js'

export default{
    name:"RegisterWorker",
    data(){
        return{
            message: "A validar registo"
        }
    },
    mounted(){
        this.registerWorker()
    },
    methods:{
        registerWorker(){
            let params = {
                work_id: this.$route.params.work_id,
                front_id: this.$route.params.front_id
            }

            QRCode.create(params).then((resp)   =>  {
                let msg = resp.data.msg
                this.message = msg
            })
        }
    }
}
</script>